<template>
  <div>
    <div class="card" style="min-height: 275px">
    <header class="card-header">
      <p class="card-header-title">
        <b-icon icon="table" custom-size="default" />
        All datasets
      </p>
        <div v-if="checkable" style="padding-top:8px; margin-right:10px;">
            <b-button rounded
              size="is-small"
              :disabled="checkedRows.length===0"
              class="is-primary button-shadow"
              icon-left="trash"
              @click="checkDelete()"
            >
            Remove
            </b-button>
            <span style='margin-left:8px'></span>
            <b-button rounded
              size="is-small"
              class="is-primary button-shadow"
              icon-left="plus"
              @click="importDataset"
            >
            Add
            </b-button>
        </div>
    </header>
    <div class="card-content">
      <b-notification
      v-if="error"
      type="is-danger"
      aria-close-label="Close notification"
      role="alert">
      {{ error }}
      </b-notification>
      <b-table
      :checked-rows.sync="checkedRows"
      :checkable="checkable"
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :data="datasets"
      default-sort-direction="asc"
      :sortable="true"
      :narrowed="true"
      :striped="true"
      :sticky-header="datasets.length>10"
      sort-icon="angle-up"
    >
      <b-table-column
        v-slot="props"
        label="Name"
        field="user_name"
        sortable
      >
        <b-tooltip v-if="props.row.user_name.length > 15">
          <template v-slot:content>{{ props.row.user_name }}</template>{{ props.row.user_name | subStr }}
        </b-tooltip>
        <span v-else>
          {{ props.row.user_name }}
        </span>
      </b-table-column>

      <b-table-column
        v-slot="props"
        label="Type"
        field="meta.nature"
        sortable
      >
        {{ props.row.meta.nature }}
      </b-table-column>
      <b-table-column field="meta.nrow" label="Rows" sortable numeric>
            <template v-slot:header="{ column }">
                <b-tooltip label="Number of rows (individuals)" append-to-body dashed>
                    {{ column.label }}
                </b-tooltip>
            </template>
            <template v-slot="props">
                {{ props.row.meta.nrow }}
            </template>
      </b-table-column>
      <b-table-column field="meta.ncol" label="Columns" sortable numeric>
            <template v-slot:header="{ column }">
                <b-tooltip label="Number of columns (variables)" append-to-body dashed>
                    {{ column.label }}
                </b-tooltip>
            </template>
            <template v-slot="props">
                {{ props.row.meta.ncol }}
            </template>
      </b-table-column>
      <b-table-column field="meta.logt" label="Log." sortable>
            <template v-slot:header="{ column }">
                <b-tooltip label="Log transformed" append-to-body dashed>
                    {{ column.label }}
                </b-tooltip>
            </template>
            <template v-slot="props">
                {{ props.row.meta.logt }}
            </template>
      </b-table-column>
      <b-table-column field="meta.normalized" label="Norm." sortable>
            <template v-slot:header="{ column }">
                <b-tooltip label="Normalized" append-to-body dashed>
                    {{ column.label }}
                </b-tooltip>
            </template>
            <template v-slot="props">
                {{ props.row.meta.normalized }}
            </template>
      </b-table-column>
      <b-table-column
        v-slot="props"
      >
        <dataset-export :dataset=props.row />
      </b-table-column>
      <section slot="empty" class="section" style="padding: 0rem">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="frown-o" size="is-large" />
            </p>
            <p>Nothing's here&hellip;</p>
          </template>
        </div>
      </section>
      <b-modal v-model="isModalActive" style="z-index: 300">
          <div class="modal-card" style="width: auto;">
              <header class="modal-card-head">
                  <p class="modal-card-title">Confirmation</p>
                  <b-button
                    class="delete is-primary button-shadow"
                    @click="close()"/>
              </header>
              <section class="modal-card-body">
                <b-notification
                  v-if="error"
                  type="is-danger"
                  aria-close-label="Close notification"
                  role="alert">
                  Unable to retrieve list of dataset impacted.<br/>
                  {{ error }}
                </b-notification>
                <template v-if="toDelete.length!==0">
                  <span v-if="toDelete.length===1"> Are you sure you want to delete {{toDelete[0]}}?</span>
                  <div v-else>
                  <ul > This action will delete the following objects:
                    <li v-for="(group,index) in toDelete" :key="index" class="">
                      - {{ group }}
                    </li>
                  </ul>
                  <p>Are you sure you want to proceed?</p>
                  </div>
                </template>
                <b-skeleton v-if="toDelete.length===0" size="is-large" :animated="true"></b-skeleton>
             </section>
              <footer class="modal-card-foot">
                <div class="has-text-right">
                  <b-button rounded
                      :disabled="toDelete.length===0"
                      label="Remove"
                      type="is-primary"
                      @click="remove()"/>
                      </div>
              </footer>
          </div>
      </b-modal>
    </b-table>
    </div>
  </div>
  </div>
</template>

<script>
import natureDictionary from '../../data/natureDictionary.json'
import apiService from '@/services/apiService'
import { useWorkspaceStore } from '@/stores/workspacestore'

export default {
  name: 'DatasetsTable',
  props: {
    dataUrl: {
      type: String,
      default: null
    },
    checkable: {
      type: Boolean,
      default: false
    },
    datasetNameProp: {
      type: String,
      default: null
    }
  },
  components: {
    'dataset-export': () => import('@/components/DatasetExport.vue')
  },
  data () {
    return {
      datasetTypes: new Map(natureDictionary.map(obj => [obj.key, obj.nature])),
      isModalActive: false,
      trashObject: null,
      isLoading: false,
      paginated: false,
      perPage: 10,
      checkedRows: [],
      datasetName: '',
      toDelete: [],
      error: ''
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore }
  },
  computed: {
    datasets: function () {
      return this.workspaceStore.datasets
    },
    selectDatasetsName: function () {
      return this.checkedRows.map(obj => obj.user_name)
    }
  },
  methods: {
    checkDelete: function () {
      this.toDelete = []
      if (this.selectDatasetsName.length > 0) {
        this.isModalActive = true
        apiService.runRFunction({
          'func_name': 'r_check_delete',
          'objectName': this.selectDatasetsName
        })
          .then(data => {
            if (typeof data.ToDelete === 'string') {
              this.toDelete = [data.ToDelete]
            } else {
              this.toDelete = data.ToDelete
            }
            if (this.toDelete.length === 0) {
              this.error = 'select dataset first'
            }
          })
          .catch(error => {
            this.error = error.data.error
          })
      }
    },
    close: function () {
      this.toDelete = []
      this.isModalActive = false
    },
    importDataset: function () {
      this.$router.push({ path: '/import-dataset' })
    },
    remove: function () {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.workspaceStore.runRFunction(
        { 'func_name': 'r_delete_object',
          'objectList': this.toDelete,
          'out_graph': true })
        .then(data => {
          this.toDelete = []
          this.checkedRows = []
          loadingComponent.close()
        })
        .catch(error => {
          this.toDelete = []
          this.checkedRows = []
          this.error = error.data.error
          loadingComponent.close()
        })
      this.isModalActive = false
    }
  },
  filters: {
    subStr: function (string) {
      return string.substring(0, 15) + '...'
    }
  }
}
</script>

<style scoped>
  .modal-card-foot {
    justify-content: right;
  }
  .card-header-icon.isDisabled {
    cursor: not-allowed;
  }
</style>
