<template>
  <div class="columns">
    <div class="column is-one-third">
      <card-component
        class="has-table has-mobile-sort-spaced"
        title="All datasets"
        icon="table"
      >
        <datasets-table
          :checkable="true"
          @dataset-selected="selectedDataset=$event"
        />
        </card-component>
    </div>
    <div class="column">
      <dataset-view v-if="selectedDataset" :dataset-name="selectedDataset.object_name" />
      <card-component v-else title="View a dataset" icon="info">
        <p>Select a dataset to view it</p>
      </card-component>
    </div>
  </div>
</template>

<script>
import DatasetsTable from '@/components/DatasetsTable'
import DatasetView from '@/components/DatasetView'
import CardComponent from '@/components/ui/CardComponent'
import { useWorkspaceStore } from '@/stores/workspacestore'
export default {
  name: 'AllDatasets',
  components: {
    CardComponent,
    DatasetView,
    DatasetsTable
  },
  data () {
    return {
      selectedDataset: null
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore }
  },
  mounted () {
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })
    this.workspaceStore.getWorkflow()
      .then(() => {
        loadingComponent.close()
      })
      .catch(() => {
        loadingComponent.close()
      })
  },
  methods: {
  }
}
</script>
